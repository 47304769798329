import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth.service';
import { BrowserService } from '@core/browser.service';
import { zbpIconNames } from '@shared/zbp-icon/zbp-icon-names';

@Component({
  selector: 'zbp-redesigned-choose-role',
  templateUrl: './redesigned-choose-role.component.html',
  styleUrls: ['./redesigned-choose-role.component.scss'],
})
export class RedesignedChooseRoleComponent implements OnInit {
  isHighlights = false;
  logoSrc: string = 'assets/zbportal/img/zb-logo.svg';
  logoAlt: string = 'My ZB Portal';
  zbpIconNames = zbpIconNames;
  containerClass: string = '';

  constructor(
    private router: Router,
    private titleService: Title,
    private authService: AuthService,
    public browserService: BrowserService,
  ) { }

  readonly roleLinkButtons = [
    {
      Label: 'Teachers & administrators',
      Icon: this.zbpIconNames.admin_teacher_role,
      Route: '/login/user',
      Id: 'teacher-admin',
    },
    {
      Label: 'Parents & guardians',
      Icon: this.zbpIconNames.parent_role,
      Route: '/login/parent',
      Id: 'parent',
    },
    {
      Label: 'Students',
      Icon: this.zbpIconNames.student_role,
      Route: '/login/student',
      Id: 'students',
    },
  ];

  ngOnInit() {
    if (this.authService.isHighlightsPortalUrl) {
      this.isHighlights = true; // this is to prevent the choose role HTML from displaying
      this.router.navigateByUrl('/login/user');
    } else {
      this.containerClass = 'zb-choose-role-container';
      this.titleService.setTitle('Login | My ZB Portal');
    }
  }

  get backToZanerBloserLink(): string {
    return 'http://www.zaner-bloser.com';
  }
}
