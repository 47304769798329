import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AuthService } from '@core/auth.service';
import { ZbPortalRedesignHelpers } from '@core/services/feature-flagged-features/zb-portal-redesign-helpers';
import { PrimaryMenu } from '@models/primary-menu';
import { CompanyCode } from '@shared/enums/company-code';
import { BrandedHeaderComponent } from '../branded-header/branded-header.component';
import { HeaderService } from '../header-service';
import { HeaderComponent } from '../header.component';

@Component({
  standalone: true,
  selector: 'zbp-header-brand-handler',
  template: '<ng-container *ngComponentOutlet="componentToUse; injector: customInjector"></ng-container>',
  imports: [CommonModule]
})
export class HeaderBrandHandlerComponent implements OnInit, OnChanges {
  @Input() areaLabel: string = '';
  @Input() menus: PrimaryMenu[] = [];
  @Input() shouldDisplayPrimaryMenus: boolean = true;
  @Output() logoClicked: EventEmitter<void> = new EventEmitter<void>();

  // Used to inject values into ZBPortal header without making updates to its existing functionality
  customInjector: Injector = null;
  componentToUse: any;
  private isZbPortal: boolean = false;

  headerComponentMap = {
    [CompanyCode.HighlightsPortal]: {
      component: BrandedHeaderComponent,
    },
    [CompanyCode.ZBPortal]: {
      component: HeaderComponent,
    },
    ZbRedesign: {
      component: BrandedHeaderComponent,
    },
  };

  ngOnInit(): void {
    const { loginBrand } = this.authService;
    if (loginBrand === CompanyCode.ZBPortal) {
      this.isZbPortal = true;
    }

    let brandComponent = this.headerComponentMap[loginBrand]?.component;

    const useRedesignedIfAvailable = loginBrand === CompanyCode.ZBPortal
      && this.zbPortalRedesignHelpers.isZbPortal2024Active();

    /**
     * If isZbPortal2024Active Feature Flag is active and there is a redesigned ZB component version,
     * then use the redesigned version for ZB Portal.
     */
    if (useRedesignedIfAvailable && this.headerComponentMap.ZbRedesign?.component) {
      brandComponent = this.headerComponentMap.ZbRedesign?.component;
    }

    if (brandComponent) {
      this.componentToUse = brandComponent;
    }

    this.setupHeaderOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setupHeaderWithChanges(changes);
  }

  constructor(private authService: AuthService,
    private headerMenuService: HeaderService,
    private zbPortalRedesignHelpers: ZbPortalRedesignHelpers
  ) { }


  private setupHeaderOnInit(): void {
    if (this.isZbPortal) {
      this.setupZbPortalHeader();
    } else {
      this.setupHlPortalHeader();
    }
  }

  private setupHeaderWithChanges(changes: SimpleChanges) {
    if (this.isZbPortal) {
      if (changes.menus?.currentValue || changes.areaLabel?.currentValue) {
        this.setupZbPortalHeader();
      }
    } else if (changes.menus?.currentValue || changes.shouldDisplayPrimaryMenus.currentValue) {
      this.setupHlPortalHeader();
      // No HL menu
    } else {
      this.headerMenuService.currentMenus = [];
      this.headerMenuService.displayPrimaryHeaderMenus = false;
    }

    // Refresh the headers, this will rebuild the headers if they are different
    this.headerMenuService.refreshHeader$.next(true);
  }

  private setupHlPortalHeader() {
    this.headerMenuService.currentMenus = this.menus;
    this.headerMenuService.displayPrimaryHeaderMenus = this.shouldDisplayPrimaryMenus;
  }

  private setupZbPortalHeader() {
    this.customInjector = Injector.create({
      providers: [
        { provide: this.headerMenuService.zbPortalMenusToken, useValue: this.menus },
        { provide: this.headerMenuService.zbPortalAreaLabelToken, useValue: this.areaLabel },
      ],
    });
  }
}
