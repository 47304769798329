import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@core/auth.service';
import { MasqueradeService } from '@core/services/masquerade/masquerade.service';
import { PrimaryMenu } from '@models/primary-menu';
import { IProductLine } from '@models/product-line';
import { IProductVariant } from '@models/product-variant';
import { IUser } from '@models/user';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '@shared/confirmation-modal/confirmation-modal.component';
import { CompanyCode } from '@shared/enums/company-code';
import { MasqueradeTextHelpers } from '@shared/masquerade-helpers/masquerade-text-helper';
import { Subscription, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { StudentSettingsComponent } from '../student-settings/student-settings.component';

import { HeaderService } from './header-service';

@Component({
  selector: 'zbp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() areaLabel: string = inject(this.headerService.zbPortalAreaLabelToken, { optional: true }) ?? '';
  @Input() menus: PrimaryMenu[] = inject(this.headerService.zbPortalMenusToken, { optional: true }) ?? [];
  @Input() products: IProductLine[] = [];
  @Output() logoClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() sideMenuToggled: EventEmitter<string> = new EventEmitter<string>();
  @Output() triggerProductDropdown: EventEmitter<IProductLine> = new EventEmitter<IProductLine>();

  user: IUser;
  hasAudioSupport = false;
  isHighlightsPortalUser = false;
  modalRef: NgbModalRef;

  private verifyStopMasqModalRef: NgbModalRef;
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private authService: AuthService,
    private headerService: HeaderService,
    private masqueradeService: MasqueradeService) { }

  get hasLtiContext(): boolean {
    return !!this.authService.externalUrl;
  }

  get isMasqueraded(): boolean {
    return this.authService.isMasqueraded;
  }

  get userNameSchoolId(): string {
    const value = this.user.getDisplayName;

    if (this.user.abbreviatedSchoolId) {
      return `${value} (${this.user.abbreviatedSchoolId})`;
    }
    return value;
  }

  get canSelfAssignLicenses(): boolean {
    return this.user.isTeacher
      && this.user.profileDetail
      && this.user.profileDetail.canManageLicenses;
  }

  get returnToLmsUrl(): string {
    return this.authService.externalUrl;
  }

  ngOnInit() {
    this.user = this.authService.user;
    this.subscriptions.push(
      this.authService.hasAudioSupport$
        .pipe(
          withLatestFrom(this.authService.loginBrand$)
        )
        .subscribe(([hasAudioSupport, companyCode]) => {
          this.hasAudioSupport = hasAudioSupport;
          this.isHighlightsPortalUser = companyCode === CompanyCode.HighlightsPortal;
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  isRouteActive(value: string): boolean {
    return this.router.url === value;
  }

  returnToLms(): boolean {
    // Logs the user out, and then return to LMS.
    this.authService.logout();
    return true;
  }

  restoreUser(): void {
    if (this.isMasqueraded) {
      if (this.masqueradeService.userRoleCxSupportOrAboveBeforeMasquerade) {
        this.masqueradeService.exitMasquerade();
      } else {
        // Using this instead of the one within the Masquerade Service in order to use the current ZB-style modal
        // Will be removed in ZBPortal resdesign and replaced with confirmStopMasqueradeModal from MasqueradeService
        this.verifyStopMasqModalRef = this.modalService.open(ConfirmationModalComponent, { size: 'lg' });

        this.verifyStopMasqModalRef.componentInstance.title = null;
        this.verifyStopMasqModalRef.componentInstance.message = MasqueradeTextHelpers.EXIT_MASQUERADE_DESCRIPTION_TEXT;
        // eslint-disable-next-line max-len
        this.verifyStopMasqModalRef.componentInstance.confirmButtonText = MasqueradeTextHelpers.CONFIRM_EXIT_MASQUERADE_TEXT;

        const stopMasqueradeModal = from(this.verifyStopMasqModalRef.result)
          .pipe(
            catchError(() => of(false)),
            mergeMap((confirmed: any) => {
              if (confirmed) {
                return of(this.masqueradeService.exitMasquerade());
              }
              return of(null);
            })
          ).subscribe();

        this.subscriptions.push(stopMasqueradeModal);
      }
    }
  }

  clickedSidebarLink(event: MouseEvent, name: string, callbackFuntion: Function = null): boolean {
    event.stopPropagation();
    this.sideMenuToggled.emit(name);

    if (callbackFuntion) {
      callbackFuntion();
    }
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  clickedDropdownSubMenu(event: MouseEvent, product: IProductLine, variant: IProductVariant) {
    event.preventDefault();
    this.triggerProductDropdown.emit(product);
  }

  goHome(event: MouseEvent): boolean {
    event.preventDefault();
    // when ZBPortalHeader called directly by components, logoClicked is passed to ZBHeader
    if (!this.isHighlightsPortalUser) {
      this.logoClicked.emit();
    }

    // when using the HeaderBrandHandler, the logoClicked function is set in the HeaderService
    if (this.headerService.logoClickedFunction) {
      this.headerService.logoClickedFunction();
    }

    return false;
  }

  openSettingsModal(): void {
    this.modalRef = this.modalService.open(StudentSettingsComponent, { size: 'lg' });

    from(this.modalRef.result)
      .pipe(catchError(() => of(false)))
      .subscribe();
  }

  chooseRoleClick() {
    this.headerService.chooseRoleClick();
  }
}
