import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/button/button.component';
import {
  HighlightsSetSchoolIdsFormComponent,
} from '@shared/highlights-set-school-ids-form/highlights-set-school-ids-form.component';
import {
  UserAnnouncementModalComponent
} from '@shared/modals/user-announcement-modal/user-announcement-modal.component';
import {
  OverviewAdminActionsComponent
} from '@shared/overview/overview-admin-actions/overview-admin-actions.component';
import {
  OverviewAdminResourcesComponent
} from '@shared/overview/overview-admin-resources/overview-admin-resources.component';
import {
  OverviewGettingStartedChecklistComponent
} from '@shared/overview/overview-getting-started-checklist/overview-getting-started-checklist.component';
import { OverviewMyProductsComponent } from '@shared/overview/overview-my-products/overview-my-products.component';
import { OverviewWelcomeComponent } from '@shared/overview/overview-welcome/overview-welcome.component';
import { PreloaderModule } from '@shared/preloader/preloader.module';
import { ZbpIconComponent } from '@shared/zbp-icon/zbp-icon';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AdminBenchmarkComponent } from '../reports/admin-benchmark/admin-benchmark.component';
import { Superkids2017BenchmarkComponent }
  from '../reports/admin-benchmark/superkids2017-benchmark/superkids2017-benchmark.component';
import {
  StudentPasswordsModalComponent
} from '../schools/school/manage-users/students/confirmation-modal/student-passwords-modal.component';
import {
  NewQrCodesModalComponent
} from '../schools/school/manage-users/students/new-qr-codes-modal/new-qr-codes-modal.component';
import {
  AdminLicenseStartEndDateTableComponent
} from './admin-license-start-end-date-table/admin-license-start-end-date-table.component';
import { BlockedDirective } from './blocked.directive';
import { BrandedFallbackDownloadComponent } from './branded-fallback-download/branded-fallback-download.component';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { CornerRibbonComponent } from './corner-ribbon/corner-ribbon.component';
import {
  BrandedCreateSchoolUsersComponent
} from './create-school-users/branded-create-school-users/branded-create-school-users.component';
import {
  CreateSchoolUsersBrandHandlerComponent
} from './create-school-users/create-school-users-brand-handler.component';
import { CreateSchoolUsersComponent } from './create-school-users/create-school-users.component';
import { IsoDatePickerAdapter } from './date-picker/iso-date-picker-adapter';
import { NonIsoDateFormatter } from './date-picker/non-iso-date-formatter';
import { DroppableDirective } from './droppable.directive';
import { EducationalUnitService } from './educational-unit/educational-unit.service';
import { ErrorDisplayPanelComponent } from './error-display-panel/error-display-panel.component';
import { ExpireLicensesDialogComponent } from './expire-licenses-dialog/expire-licenses-dialog.component';
import { FallbackDownloadComponent } from './fallback-download/fallback-download.component';
import { FileInputComponent } from './file-input/file-input.component';
import { FooterComponent } from './footer/footer.component';
import { GettingStartedChecklistComponent } from './getting-started-checklist/getting-started-checklist.component';
import { BrowserGlobalRef, GlobalRef } from './global-ref';
import { HeaderComponent } from './header/header.component';
import { HelpPopUpComponent } from './help-pop-up/help-pop-up.component';
import { IsoDatePipe } from './iso-date/iso-date.pipe';
import { LessonPlannerComponent } from './lesson-planner/lesson-planner.component';
import { LicenseExpiredPopOverComponent } from './license-expired-pop-over/license-expired-pop-over.component';
import { MakeTextFitDirective } from './make-text-fit.directive';
import { AdminsTableBrandHandlerComponent } from './manage-users/admins/admins-table-brand-handler.component';
import { BrandedAdminsTableComponent } from './manage-users/admins/branded-admins-table/branded-admins-table.component';
import { BrandedManageUsersComponent } from './manage-users/branded-manage-users/branded-manage-users.component';
import {
  BrandedTeachersTableComponent
} from './manage-users/teachers/branded-teachers-table/branded-teachers-table.component';
import { TeachersTableBrandHandlerComponent } from './manage-users/teachers/teachers-table-brand-handler.component';
import { MarkdownService } from './markdown.service';
import { GenericConfirmModalComponent } from './modals/generic-confirm-modal/generic-confirm-modal.component';
import { QrCodeHelpModalComponent } from './modals/qr-code-help-modal/qr-code-help-modal.component';
import { NoLicenseComponent } from './no-license/no-license.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PinProgressBarComponent } from './pin-progress-bar/pin-progress-bar.component';
import { ObjToKeyValPairsPipe } from './pipes/obj-to-key-val-pairs.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { QuestDatePickerComponent } from './quest-date-picker/quest-date-picker.component';
import { QuestLaunchDirective } from './quest-launch.directive';
import { SortColumnsService } from './sort-columns.service';
import { StarProgressBarComponent } from './star-progress-bar/star-progress-bar.component';
import { StudentAudioButtonComponent } from './student-audio-button/student-audio-button.component';
import { StudentAvatarComponent } from './student-avatar/student-avatar.component';
import { StudentAvatarModalComponent } from './student-avatar-modal/student-avatar-modal.component';
import { StudentAvatarStoryModalComponent } from './student-avatar-story-modal/student-avatar-story-modal.component';
import { StudentSettingsComponent } from './student-settings/student-settings.component';
import { TextSearchComponent } from './text-search/text-search.component';
import { UserAnnouncementsComponent } from './user-announcements/user-announcements.component';
import { BrandedWistiaComponent } from './wistia/branded-wistia/branded-wistia.component';
import { WistiaComponent } from './wistia/wistia.component';
import { WistiaService } from './wistia/wistia.service';
import { ZbSwirlDirective } from './zb-swirl.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
    EditorModule,
    ZbpIconComponent,
    NgOptimizedImage,
    ButtonComponent,
    AdminLicenseStartEndDateTableComponent,
    PreloaderModule,
    TextSearchComponent,
  ],
  declarations: [
    BlockedDirective,
    ErrorDisplayPanelComponent,
    FallbackDownloadComponent,
    FooterComponent,
    HeaderComponent,
    LicenseExpiredPopOverComponent,
    MakeTextFitDirective,
    ObjToKeyValPairsPipe,
    PageNotFoundComponent,
    QuestLaunchDirective,
    QuestDatePickerComponent,
    SafeHtmlPipe,
    StarProgressBarComponent,
    StudentAvatarComponent,
    StudentAvatarModalComponent,
    StudentAvatarStoryModalComponent,
    UserAnnouncementsComponent,
    WistiaComponent,
    ZbSwirlDirective,
    ColorPickerComponent,
    CreateSchoolUsersComponent,
    BrandedCreateSchoolUsersComponent,
    CreateSchoolUsersBrandHandlerComponent,
    NoLicenseComponent,
    PinProgressBarComponent,
    StripHtmlPipe,
    StudentPasswordsModalComponent,
    NewQrCodesModalComponent,
    HelpPopUpComponent,
    GettingStartedChecklistComponent,
    DroppableDirective,
    LessonPlannerComponent,
    CheckboxGroupComponent,
    ExpireLicensesDialogComponent,
    CornerRibbonComponent,
    StudentAudioButtonComponent,
    StudentSettingsComponent,
    QrCodeHelpModalComponent,
    IsoDatePipe,
    OverviewWelcomeComponent,
    OverviewGettingStartedChecklistComponent,
    OverviewAdminResourcesComponent,
    OverviewMyProductsComponent,
    OverviewAdminActionsComponent,
    GenericConfirmModalComponent,
    HighlightsSetSchoolIdsFormComponent,
    BrandedManageUsersComponent,
    BrandedTeachersTableComponent,
    TeachersTableBrandHandlerComponent,
    BrandedAdminsTableComponent,
    AdminsTableBrandHandlerComponent,
    BrandedWistiaComponent,
    FileInputComponent,
    BrandedFallbackDownloadComponent,
    NumberInputComponent,
    UserAnnouncementModalComponent,
    AdminBenchmarkComponent,
    Superkids2017BenchmarkComponent,
  ],
  exports: [
    BlockedDirective,
    ColorPickerComponent,
    CommonModule,
    ErrorDisplayPanelComponent,
    FallbackDownloadComponent,
    FooterComponent,
    FormsModule,
    HeaderComponent,
    LicenseExpiredPopOverComponent,
    MakeTextFitDirective,
    NgbModule,
    NgSelectModule,
    ObjToKeyValPairsPipe,
    StripHtmlPipe,
    QuestLaunchDirective,
    QuestDatePickerComponent,
    ReactiveFormsModule,
    StarProgressBarComponent,
    StudentAvatarComponent,
    UserAnnouncementsComponent,
    ZbSwirlDirective,
    PinProgressBarComponent,
    HelpPopUpComponent,
    GettingStartedChecklistComponent,
    LessonPlannerComponent,
    DroppableDirective,
    TranslateModule,
    CheckboxGroupComponent,
    CornerRibbonComponent,
    StudentAudioButtonComponent,
    IsoDatePipe,
    SafeHtmlPipe,
    OverviewWelcomeComponent,
    OverviewGettingStartedChecklistComponent,
    OverviewAdminResourcesComponent,
    OverviewMyProductsComponent,
    OverviewAdminActionsComponent,
    HighlightsSetSchoolIdsFormComponent,
    BrandedCreateSchoolUsersComponent,
    BrandedManageUsersComponent,
    BrandedTeachersTableComponent,
    TeachersTableBrandHandlerComponent,
    BrandedAdminsTableComponent,
    AdminsTableBrandHandlerComponent,
    TeachersTableBrandHandlerComponent,
    TextSearchComponent,
    FileInputComponent,
    BrandedFallbackDownloadComponent,
    NumberInputComponent
  ],
  providers: [
    EducationalUnitService,
    MarkdownService,
    SortColumnsService,
    Title,
    WistiaService,
    { provide: NgbDateAdapter, useClass: IsoDatePickerAdapter },
    { provide: NgbDateParserFormatter, useClass: NonIsoDateFormatter },
  ],
})
export class SharedModule {
  static forBrowser(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: GlobalRef, useClass: BrowserGlobalRef }
      ]
    };
  }
}
