import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { ApiResponse } from '@models/api-response';
import { ApplicationUser } from '@models/application-user';
import { ClassroomIdentifier } from '@models/classroom-id';
import { ILtiAuthenticateRequest } from '@models/lti-advantage';
import { IUser } from '@models/user';
import { UserPrefs } from '@models/user/user-prefs';
import { UserStatusResponse } from '@models/user-response';
import { UserRoleDomains } from '@models/user-role-company-codes';
import { CompanyCode } from '@shared/enums/company-code';
import { CompanyHost } from '@shared/enums/company-host';
import { RoleType } from '@shared/enums/role-type';
import { Helpers } from '@shared/helpers';
import { MasqueradeTextHelpers } from '@shared/masquerade-helpers/masquerade-text-helper';
import { copyObject } from '@shared/zb-object-helper/object-helper';
import { instantiateApiResponseFromJson } from '@shared/zb-rxjs-operators/rxjs-operators';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, interval, Observable, of, Subscription, throwError } from 'rxjs';
import {
  catchError, distinctUntilChanged, filter,
  map, mergeMap, shareReplay, skipWhile, startWith, switchMap
} from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { AppConfigService } from './appconfig.service';
import { CacheService } from './cache.service';
import { EmailApiService } from './services/application/email/email-api.service';

export interface IAuthService {
  assetUrl: string;
  avatarUrl: string;
  coreApiUrl: string;
  externalUrl: string;
  apiToken: string;
  userId: string;
  user: IUser;
  hasLicenses: boolean;
  hasAgreed: boolean;
  inSkofApp: boolean;
  isMasqueraded: boolean;
  isProduction: boolean;

  classrooms: ClassroomIdentifier[];
  authStatus: BehaviorSubject<boolean>;
  hasAudioSupport$: BehaviorSubject<boolean>;

  /**
   * The login brand represents brand identified by the URL when the app is loaded.
   */
  loginBrand$: BehaviorSubject<CompanyCode>;

  addClassroom(classroom: ClassroomIdentifier): void;
  setUserData(data: UserStatusResponse): void;
  /**
   * Update the student audio support.
   *
   * @param {boolean} value - the value to set if resetProfile is true.
   * @param {boolean} resetProfile - whether to update the profileDetail to value.
   */
  resetAudioSupport(value: boolean, resetProfile: boolean): void;
  resetPassword(email: string): Observable<ApiResponse<boolean>>;
  resetActivationOrPassword(email: string): Observable<ApiResponse<boolean>>;
  loginWithLtiToken(token: string): Observable<ApiResponse<UserStatusResponse>>;
  loginWithLtiAdvantageToken(data: ILtiAuthenticateRequest): Observable<ApiResponse<UserStatusResponse>>;
  loginAs(email: string): Observable<ApiResponse<boolean>>;
  loginAsStudent(userId: string): Observable<ApiResponse<boolean>>;
  setRoleView(roleType: RoleType): Observable<ApiResponse<boolean>>;
  isAuthorizedForStudentActivity(): boolean;
  setBrandFromUrl(): void;
  setLoginBrand(value: CompanyCode): void;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService implements IAuthService, OnDestroy {
  // store the URL so we can redirect after logging in.
  redirectUrl: string;
  siteStatusMessage: string = '';
  siteTitle: string = 'Site Maintenance';
  siteStatus: Observable<boolean>;
  hasAudioSupport$: BehaviorSubject<boolean>;
  inSkofApp = false;
  hasLicenses = false;
  hasAgreed = false;
  firstLogin = false;
  loginBrand$: BehaviorSubject<CompanyCode> = new BehaviorSubject(CompanyCode.ZBPortal);

  // ZBPortal-Api
  private _authStatus: BehaviorSubject<boolean>;
  private _coreAssetUrl = null;
  private _user: IUser;
  private _siteStatus: BehaviorSubject<boolean>;
  private _passResetToken: string;
  private _classrooms: ClassroomIdentifier[] = [];
  private _userId: string = null;
  private _getUserPrefs: boolean = true;
  private _isLoggingOut: boolean = false;

  subscriptions: Subscription[] = [];
  user$: BehaviorSubject<ApplicationUser> = new BehaviorSubject<ApplicationUser>(null);
  updateUserPrefs$: BehaviorSubject<UserPrefs> = new BehaviorSubject<UserPrefs>(null);

  filteredUpdatedUserPrefs$: Observable<UserPrefs> = this.updateUserPrefs$.pipe(
    filter(updatedUserPrefs => !!updatedUserPrefs),
    startWith(null),
    distinctUntilChanged(),
    shareReplay()
  );

  userPrefs$: Observable<UserPrefs> = combineLatest([this.filteredUpdatedUserPrefs$, this.user$])
    .pipe(
      filter(([, user]) => !!user),
      map(([updateUserPrefs, user]) => ({ updateUserPrefs, user })),
      switchMap(({ updateUserPrefs, user }) => {
        if (user.profileDetail?.viewingAsRole === RoleType.Teacher) {
          if (this._getUserPrefs && !updateUserPrefs && user.profileDetail?.viewingAsRole) {
            this._getUserPrefs = false;
            return this.getUserPrefsForCurrentRoleType(user.profileDetail.viewingAsRole);
          }
          if (updateUserPrefs && !this._isLoggingOut) {
            this.updateUserPrefs$.next(null);
            return this.postUserPrefsForCurrentRoleType(user.profileDetail.viewingAsRole, updateUserPrefs);
          }
        }
        return of(new UserPrefs());
      }),
      shareReplay()
    );

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private cache: CacheService,
    private emailApiService: EmailApiService,
  ) {
    this._siteStatus = new BehaviorSubject<boolean>(true);
    this.siteStatus = this._siteStatus.asObservable();
    this.hasAudioSupport$ = new BehaviorSubject<boolean>(false);
    this.handleError = this.handleError.bind(this);
    this.subscriptions.push(
      this.initializeTimeout(),
      this.userPrefs$.subscribe()
    );
  }

  private handleError(error: any): Promise<any> {
    let errorMessage = null;
    try {
      if (error instanceof HttpErrorResponse && error.error) {
        // Error messages are often returned in the body.
        const [errorValue] = error.error;
        errorMessage = errorValue;
      } else if (error.status === 503) {
        this.siteStatusMessage = error.error instanceof Array ? error.error[0] : '';
        this.setSiteStatus(false);
      } else {
        this.siteStatusMessage = '';
        this.setSiteStatus(true);
      }
    } catch (ex) {
      console.error(ex);
    }

    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(errorMessage || error.message || error.statusText || error);
  }

  get apiToken(): string {
    return this.cache.coreApiToken;
  }

  get isMasqueraded(): boolean {
    return this.cache.isMasqueraded;
  }

  set isMasqueraded(value: boolean) {
    this.cache.isMasqueraded = value;
  }

  get authStatus(): BehaviorSubject<boolean> {
    if (!this._authStatus) {
      this._authStatus = new BehaviorSubject<boolean>(null);
      if (this.apiToken) {
        // Checks that the token is still valid by making a request.
        this.getAuthorizationStatus();
      } else {
        this._authStatus.next(false);
      }

    }
    return this._authStatus;
  }

  /**
   * Current Authenticated User
   */
  get user(): IUser {
    return this._user;
  }

  set user(user: IUser) {
    this._user = user;
    this.user$.next(copyObject(user, ApplicationUser));
  }

  get userId(): string {
    return this._userId;
  }

  get classrooms(): ClassroomIdentifier[] {
    return this._classrooms;
  }

  get avatarUrl(): string {
    return `${this._coreAssetUrl}avatar`;
  }

  get assetUrl(): string {
    return this._coreAssetUrl;
  }

  get passResetToken(): string {
    return this._passResetToken;
  }

  set externalUrl(value: string) {
    if (this.user.lti && value) {
      this.user.lti.launchPresentationReturnUrl = value;
    } else if (!this.user.lti && value) {
      this.user.lti = { launchPresentationReturnUrl: value };
    } else if (this.user.lti && !value) {
      this.user.lti.launchPresentationReturnUrl = null;
    }
  }

  get externalUrl(): string {
    return this.user?.lti?.launchPresentationReturnUrl;
  }

  clearPassResetToken(): void {
    this._passResetToken = null;
  }

  setSiteStatus(value: boolean) {
    this._siteStatus.next(value);
  }

  get coreApiUrl(): string {
    return this.appConfig.apiUrl;
  }

  get isProduction(): boolean {
    return environment.environment === 'prod';
  }

  get isHighlightsPortalUrl(): boolean {
    return this.loginBrand === CompanyCode.HighlightsPortal;
  }

  getAuthUrl(path: string): string {
    return `${this.appConfig.apiUrl}/${path}`;
  }

  setLoginBrand(value: CompanyCode) {
    this.loginBrand$.next(value);
  }

  get loginBrand(): CompanyCode {
    return this.loginBrand$.value;
  }

  addClassroom(classroom: ClassroomIdentifier): void {
    if (!this._classrooms.some(c => c.classroomId === classroom.classroomId
      && c.integrationId === classroom.integrationId)) {
      this._classrooms.push(classroom);
    }
  }
  removeClassroomById(classroomId: string): void {
    this._classrooms = this._classrooms.filter(c => c.classroomId !== classroomId);
  }

  /**
   * Authenticate the user with the supplied credentials, returns success or error
   * This method will also update the CSRF token
   * @param username: email address if teacher, or userid_schoolid if student
   * @param password
   * @param isStudent: whether or not the user is a student
   */
  login(userName: string, password: string, isStudent: boolean = false): Observable<ApiResponse<UserStatusResponse>> {
    const data: any = { userName, password, isStudent, rememberMe: false };
    return this.http.post<ApiResponse<UserStatusResponse>>(this.getAuthUrl('user/login'), data)
      .pipe(
        map(res => new ApiResponse<UserStatusResponse>(true, res)),
        map((res) => {
          if (res.success) {
            this._isLoggingOut = false;
            if (res.response.user) {
              this.cache.coreApiToken = res.response.token;
              this.setUserData(res.response);
              // On login, let the user choose their role if they have multiple
              this.resetViewingAsRoleIfMultiple();
            }
            this.authStatus.next(true);
          }
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(new ApiResponse<UserStatusResponse>(false, err.error));
        })
      );
  }

  logout(): void {
    this._isLoggingOut = true;
    if (this.isMasqueraded) {
      this.restoreLogin().subscribe();
    }

    if (this._userId) {
      this.logoutUser().subscribe();
    }

    this.clearUserState();
  }

  loginWithLtiToken(token: string): Observable<ApiResponse<UserStatusResponse>> {
    const data = { token };
    return this.http.post<ApiResponse<UserStatusResponse>>(this.getAuthUrl('integration/lti/authenticate'), data)
      .pipe(
        map(res => new ApiResponse<UserStatusResponse>(true, res)),
        map((res) => {
          if (res.success) {
            this._isLoggingOut = false;
            if (res.response.user) {
              this.cache.coreApiToken = res.response.token;
              this.setUserData(res.response);

            }
            this.authStatus.next(true);
          }
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(new ApiResponse<UserStatusResponse>(false, err.error));
        })
      );
  }

  loginWithLtiAdvantageToken(data: ILtiAuthenticateRequest): Observable<ApiResponse<UserStatusResponse>> {
    const item: any = {
      idToken: data.token,
      state: data.state,
      platformId: data.platformId,
      companyCodeType: data.companyCodeType,
      zbNum: data.zbNum,
    };

    return this.http.post<ApiResponse<UserStatusResponse>>(this.getAuthUrl('lti-advantage-authenticate'), item)
      .pipe(
        map(res => new ApiResponse<UserStatusResponse>(true, res)),
        map((res) => {
          if (res.success) {
            this._isLoggingOut = false;
            if (res.response.user) {
              this.cache.coreApiToken = res.response.token;
              this.setUserData(res.response);
            }
            this.authStatus.next(true);
          }
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(new ApiResponse<UserStatusResponse>(false, err.error));
        })
      );
  }

  restoreLogin(): Observable<ApiResponse<UserStatusResponse>> {
    return this.http.request<ApiResponse<UserStatusResponse>>('delete', this.getAuthUrl('user/masquerade'))
      .pipe(
        map(res => new ApiResponse<UserStatusResponse>(true, res)),
        map((res) => {
          // Clear the masquerading data, but do not clear the feature flags
          // User can to exit masquerading without clearing feature flags that masquerading may be using
          this.clearUserState(false, false, true);

          this.cache.coreApiToken = res.response.token;

          return res;
        }),
      );
  }
  /* eslint-disable max-len*/
  /**
   * Used for masquerading as other users
   * @param email User to masquerade as
   * @param verifyDomainAllowed Original masquerade logic allows for any domain. Multi-Level Masquerade prevents masquerading on domains when user to masquerade as does not have roles for that domain.
   */
  /* eslint-enable max-len*/
  loginAs(email: string, verifyDomainAllowed: boolean = false): Observable<ApiResponse<boolean>> {
    return this.http.post(this.getAuthUrl(`user/masquerade/${encodeURIComponent(email)}`), '')
      .pipe(
        map((res: ApiResponse<UserStatusResponse>) => res.response),
        map((status: UserStatusResponse) => {
          const response = new ApiResponse<boolean>(true, {});
          if (status.user) {
            if (verifyDomainAllowed) {
              let hasRoleOnCurrentDomain = false;

              status.user?.userMasqueradeCompanyCodes.forEach((role: UserRoleDomains) => {
                if (role.companyCodes.includes(this.loginBrand)) {
                  hasRoleOnCurrentDomain = true;

                }
              });

              if (hasRoleOnCurrentDomain) {
                if (this.user.isInternal) {
                  this.cache.userRoleCxSupportOrAboveBeforeMasquerade = true;
                }
                this.clearUserState(false, false, false);
                this.cache.coreApiToken = status.token;
                this.isMasqueraded = true;
                this.setUserData(status);
              } else {
                response.messages = [MasqueradeTextHelpers.getUnableToMasqueradeText(this.loginBrand)];
                response.success = false;
              }
            } else {
              if (this.user.isInternal) {
                this.cache.userRoleCxSupportOrAboveBeforeMasquerade = true;
              }
              this.clearUserState(false, false, false);
              this.cache.coreApiToken = status.token;
              this.isMasqueraded = true;
              this.setUserData(status);
            }
          } else {
            response.success = false;
            response.response = false;
            Helpers.setMessages(['User data not returned']);
          }

          return response;
        }),
      );
  }

  loginAsStudent(userId: string): Observable<ApiResponse<boolean>> {
    return this.http.post(this.getAuthUrl(`associated-user/${userId}/login`), '')
      .pipe(
        map((res: ApiResponse<UserStatusResponse>) => res.response),
        map((status: UserStatusResponse) => {
          const response = new ApiResponse<boolean>(true, { response: true, messages: [] });
          if (status.user) {
            this.clearUserState(false);
            this.cache.coreApiToken = status.token;
            // Do emit, so root can change body classes.
            this.setUserData(status, true);
          } else {
            response.success = false;
            response.response = false;
            Helpers.setMessages(['User data not returned']);
          }

          return response;
        }),
      );
  }

  clearUserState(emit = true, shouldClearFeatureFlags = false, shouldClearMasqueradingData = true): void {
    this.user = copyObject({ locale: 'en-us' }, ApplicationUser);
    this._userId = null;
    this.hasLicenses = false;
    this._coreAssetUrl = null;
    this.isMasqueraded = false;
    this._getUserPrefs = true;
    // Feature flags and masquerading info do not always need removed
    // For example, this allows data and flags to persist to the beginning of masquerading sessions
    this.cache.clearAll(shouldClearFeatureFlags, shouldClearMasqueradingData);
    this.hasAudioSupport$.next(false);

    if (emit) {
      this.authStatus.next(false);
    }

    this._isLoggingOut = false;
  }

  checkTokenStatus(): Observable<boolean> {
    return this.http.get<ApiResponse<UserStatusResponse>>(this.getAuthUrl('user/status'))
      .pipe(
        map(() => true),
        // If an error occurs the Api is having CORS issues if we don't get a valid response.
        catchError((err: HttpErrorResponse) => of(err.type === HttpEventType.ResponseHeader))
      );
  }

  resetAudioSupport(value: boolean = false, resetProfile: boolean = false): void {
    if (this._user.isStudent && !!this._user.profileDetail) {
      if (resetProfile) {
        this._user.profileDetail.enableStudentAudioPrompts = value;
      }
      this.hasAudioSupport$.next(this._user.profileDetail.enableStudentAudioPrompts);
    } else {
      this.hasAudioSupport$.next(value);
    }
  }

  setUserData(data: UserStatusResponse, emit: boolean = false): Observable<ApplicationUser> {
    this._coreAssetUrl = data.assetUrl;
    this.hasLicenses = data.hasLicenses;
    this.hasAgreed = !data.needsToAcceptLatestEula;
    const userObj = {
      ...data.user,
      classrooms: data.classrooms || [],
      schools: data.schools || [],
      districts: data.districts || [],
      ssoLoginUrl: data.ssoLoginUrl,
    };

    const user = copyObject(userObj, ApplicationUser);

    this.user = user;
    this._classrooms = this._user.classrooms.map(c => ({
      integrationId: null,
      ...(_.pick(c, ['classroomId']) as ClassroomIdentifier),
    }));

    this._userId = this._user.userId;
    this.resetAudioSupport();

    if (emit) {
      this.authStatus.next(true);
    }

    if (this._user.profileDetail?.viewingAsRole === RoleType.Teacher) {
      this._getUserPrefs = true;
    }
    return of(user);
  }

  getAuthorizationStatus(): void {
    this.http.get<ApiResponse<UserStatusResponse>>(this.getAuthUrl('user/status'))
      .pipe(
        map(res => new ApiResponse<UserStatusResponse>(true, res)),
        map((res) => {
          if (res.success) {
            this.setUserData(res.response);
            this.inSkofApp = !!window.zbLoginInterop || false;

          }
          return res.success;
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401 || err.status === 403) {
            this.clearUserState();
          }
          return throwError(err);
        })
      )
      .subscribe((status) => {
        this._authStatus.next(status);
      });
  }

  // Initializes an interval to poll to get current authorization status and apiToken value. The apiToken
  // may have been removed by a separate front end application instance.
  private initializeTimeout(): Subscription {
    return interval(2500)
      .pipe(
        mergeMap(() => this.authStatus.asObservable()),
        skipWhile(status => status === null)
      )
      .subscribe((status: boolean) => {
        if (status && (!this.apiToken || this.apiToken.length === 0)) {
          this.authStatus.next(false);
        }
      });
  }

  /**
   * Triggers a password reset email for a teacher.  Not intended for use with students
   */
  resetPassword(email: string): Observable<ApiResponse<boolean>> {
    return this.emailApiService.resetPassword(email)
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(new ApiResponse<boolean>(false, err.error));
        })
      );
  }

  resetActivationOrPassword(email: string): Observable<ApiResponse<boolean>> {
    const params = [{ key: 'toAddress', value: email }];
    const endpoint = '/user/email/resend';
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, endpoint, params);
    return this.http.patch(url, {})
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  resendUserEmail(userId: string): Observable<ApiResponse<boolean>> {
    const params = [{ key: 'userId', value: userId }];
    const endpoint = `/user/email`;
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, endpoint, params);
    return this.http.patch(url, {})
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  resendFromEmailLog(email: string): Observable<ApiResponse<boolean>> {
    const params = [];
    const endpoint = `/email/email-log/${email}`;
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, endpoint, params);
    return this.http.patch(url, {})
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  logoutUser(): Observable<ApiResponse<boolean>> {
    return this.http.post(this.getAuthUrl('user/logout'), null)
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  setRoleView(roleType: RoleType): Observable<ApiResponse<boolean>> {
    const params = [{ key: 'roleType', value: roleType }];
    const endpoint = `/user/${this.userId}/roleview`;
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, endpoint, params);
    return this.http.patch(url, {})
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }

  isAuthorizedForStudentActivity(): boolean {
    return this._user.isParent || this._user.isDistrictAdmin || this._user.isSchoolAdmin;
  }

  getUserPrefsForCurrentRoleType(roleType: RoleType): Observable<UserPrefs> {
    const url = `${this.appConfig.apiUrl}/user/prefs?roleType=${roleType}`;
    return this.http.get(url)
      .pipe(
        map((res) => {
          if (res === null) {
            return { response: {}, messages: [] };
            // eslint-disable-next-line
          } else if ((res as any).response === null) {
            (res as any).response = {};
          }
          return res;
        }),
        instantiateApiResponseFromJson(UserPrefs),
        map(res => res.response),
      );
  }

  postUserPrefsForCurrentRoleType(roleType: RoleType, userPrefs: UserPrefs): Observable<UserPrefs> {
    const url = `${this.appConfig.apiUrl}/user/prefs?roleType=${roleType}`;
    if (roleType) {
      return this.http.post(url, userPrefs.toJSON())
        .pipe(
          instantiateApiResponseFromJson(UserPrefs),
          map(res => res.response),
        );
    }
    throw new Error('No role type found');
  }

  setBrandFromUrl() {
    // Set loginBrand based on the URL
    let loginBrand = CompanyCode.ZBPortal;
    if (window.location.hostname.includes(CompanyHost.HighlightsPortal)) {
      loginBrand = CompanyCode.HighlightsPortal;
    }
    this.setLoginBrand(loginBrand);
  }

  resetViewingAsRoleIfMultiple() {
    if (this.user.roles.length > 1) {
      this.user.profileDetail.viewingAsRole = null;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
