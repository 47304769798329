<main *ngIf="!isLoading" id="brandedLoginPage" [class]="brandClass">
  <nav *ngIf="!isHighlights" class="back-to-website-link-container">
    <a class="text-uppercase back-to-website-link" href="http://www.zaner-bloser.com" aria-label="Back to Zaner-Bloser's Website" >
      Return to Zaner-Bloser.com
    </a>
  </nav>
  <!-- HL Portal and ZB Designs are slightly different, but similar enough to reuse the same component -->
  <div class="content-pane" [ngClass]="isHighlights ? 'col-lg-6 col-xl-6 col-xxl-6 col-sm-10 col-xs-12' : ''">
    <figure class="brand-logo-container">
      <img class="brand-logo-img" [src]="logoSrc" [alt]="logoAlt">
    </figure>
    <div class="login-form-container">
      <zbp-login-form></zbp-login-form>
    </div>
    <!-- HL Portal footer spans half page width -->
    <zbp-footer *ngIf="isHighlights" class="w-100"></zbp-footer>
  </div>
  <!-- ZB Portal footer spans full page width -->
  <zbp-footer *ngIf="!isHighlights" class="w-100"></zbp-footer>
</main>
 