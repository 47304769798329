export const zbpIconNames = {
  arrow_right: 'arrow_right',
  arrow_chevron_right: 'arrow_chevron_right',
  arrow_chevron_left: 'arrow_chevron_left',
  smileyFace: 'smileyFace',
  thumbtack: 'thumbtack',
  printer: 'printer',
  x_cross: 'x_cross',
  x_cross_circle: 'x_cross_circle',
  t_cross: 't_cross',
  flip: 'flip',
  pencil: 'pencil',
  ellipse: 'ellipse',
  ellipse_outline: 'ellipse_outline',
  eye: 'eye',
  custom_card: 'custom_card',
  download: 'download',
  neutral: 'neutral',
  backArrow: 'backArrow',
  calendar: 'calendar',
  copy_enabled: 'copy_enabled',
  copy_disabled: 'copy_disabled',
  paste_enabled: 'paste_enabled',
  paste_disabled: 'paste_disabled',
  vertical_ellipses: 'vertical_ellipses',
  check_mark: 'check_mark',
  check_circle: 'check_circle',
  trash_can: 'trash_can',
  key: 'key',
  cog: 'cog',
  mask: 'mask',
  envelope: 'envelope',
  delete_all: 'delete_all',
  upload: 'upload',
  file: 'file',
  info_circle: 'info_circle',
  unchecked_box: 'unchecked_box',
  checked_box: 'checked_box',
  move_handle: 'move_handle',
  resize_handle: 'resize_handle',
  wrenches: 'wrenches',
  parent_role: 'parent_role',
  admin_teacher_role: 'admin_teacher_role',
  student_role: 'student_role',
};
