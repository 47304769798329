import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Helpers } from '@shared/helpers';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

import { AuthService } from './auth.service';

/**
 * AuthGuard ensures the user is authenticated before a route can be accessed.
 * Unauthenticated users will be redirected to the login
 */
@Injectable()
export class AuthGuard  {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const { url } = state;

    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

  checkLogin(url: string): Observable<boolean> {
    return this.authService.authStatus
      .pipe(
        skipWhile(status => status === null),
        map((status) => {
          if (status) {
            if (!this.authService.hasAgreed && url !== '/agreement') {
              this.router.navigateByUrl('/agreement');
            }

            return status;
          }
          if (!Helpers.isAllowedUnauthorizedRoute(url)) {
            // Stores the attempted URL for redirect later.
            this.authService.redirectUrl = url;

            // Navigates to the login page.
            const route = (!!window.zbLoginInterop || false) ? '/login/skof' : '/login';
            this.router.navigateByUrl(route);
          } else if (!status) {
            // Return true if unauthorized route is allowed so that the auth guard will allow routing to continue
            // in a routing module.
            return true;
          }

          return status;
        })
      );
  }
}
