import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { AuthService } from '@core/auth.service';
import { CompanyCode } from '@shared/enums/company-code';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { FeatureFlagService } from './feature-flag.service';
import { ZbPortalRedesignHelpers } from './services/feature-flagged-features/zb-portal-redesign-helpers';

const HIGHLIGHTS_THEME = 'brand-highlights-theme';
const ZB_THEME = 'brand-zb-theme';
const ZB_THEME_REDESIGN_CLASS = 'brand-zb-redesign-theme';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  renderer: Renderer2 = this.rendererFactory.createRenderer(null, null);
  faviconLink: Element = this.renderer.createElement('link');
  theme$: BehaviorSubject<string> = new BehaviorSubject(ZB_THEME);

  constructor(
    private rendererFactory: RendererFactory2,
    private authService: AuthService,
    private zbRedesignHelpers: ZbPortalRedesignHelpers,
    private featureFlagService: FeatureFlagService,
  ) {
    combineLatest([
      this.authService.loginBrand$,
      this.featureFlagService.featureFlagsLoaded$
    ]).subscribe(([brand]) => {
      this.applyBrandTheme(brand);
    });
  }

  private applyBrandTheme(brand: CompanyCode) {
    this.setTheme(brand);
    this.setFavIcon(brand);
  }

  setFavIcon(brand: CompanyCode) {
    this.renderer.setAttribute(this.faviconLink, 'rel', 'icon');
    this.renderer.setAttribute(this.faviconLink, 'type', 'image/x-icon');

    switch (brand) {
    case CompanyCode.HighlightsPortal:
      this.renderer.setAttribute(this.faviconLink, 'href', 'assets/img/highlights_favicon.ico');
      break;
    case CompanyCode.ZBPortal:
    default:
      this.renderer.setAttribute(this.faviconLink, 'href', 'assets/img/zb_favicon.ico');
    }

    this.renderer.appendChild(document.head, this.faviconLink);
  }

  setTheme(brand: CompanyCode) {
    this.renderer.removeClass(document.body, HIGHLIGHTS_THEME);
    this.renderer.removeClass(document.body, ZB_THEME);
    this.renderer.removeClass(document.body, ZB_THEME_REDESIGN_CLASS);

    switch (brand) {
    case CompanyCode.HighlightsPortal:
      this.renderer.addClass(document.body, HIGHLIGHTS_THEME);
      this.theme$.next(HIGHLIGHTS_THEME);
      break;
    case CompanyCode.ZBPortal:
    default:
      if (this.zbRedesignHelpers.isZbPortal2024Active()) {
        this.renderer.addClass(document.body, ZB_THEME_REDESIGN_CLASS);
        this.theme$.next(ZB_THEME_REDESIGN_CLASS);
        break;
      }
      this.renderer.addClass(document.body, ZB_THEME);
      this.theme$.next(ZB_THEME);
    }
  }
}
