import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth.service';
import { zbpIconNames } from '@shared/zbp-icon/zbp-icon-names';
import { interval, Subscription } from 'rxjs';
import { mergeMap, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'zbp-branded-site-down',
  templateUrl: './branded-site-down.component.html',
  styleUrls: ['./branded-site-down.component.scss'],
})
export class BrandedSiteDownComponent implements OnDestroy, OnInit {
  protected readonly zbpIconNames = zbpIconNames;

  message: string = 'The portal will be back up soon. Please check back later.';
  title: string = 'Sorry, we\'re down for maintenance.';
  up = true;

  private interval = false;
  private subscription: Subscription;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.authService.siteStatus.subscribe((up: boolean) => {
      this.up = up;
      if (this.up) {
        // Redirects back home if the site is up again.
        this.router.navigateByUrl('/');
      } else if (!this.interval) {
        this.checkStatus();
      }
    });
  }

  ngOnDestroy() {
    this.interval = false;
    if (this.subscription) {
      // Cleans up the subscription on route change otherwise the interval will continue to make
      // requests to check the site status even after the component is destroyed.
      this.subscription.unsubscribe();
      this.subscription.remove(this.subscription);
    }
  }

  private checkStatus() {
    this.interval = true;
    this.subscription = interval(120000)
      .pipe(
        takeWhile(() => !this.up),
        mergeMap(() => this.authService.checkTokenStatus()),
      )
      .subscribe((state: boolean) => {
        this.authService.setSiteStatus(state);
      });
  }
}
