import { Component } from '@angular/core';
import { CompanyCode } from '@shared/enums/company-code';
import { ViewHandlerComponent } from '@shared/view-handler/view-handler.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import {
  HighlightsForgotPasswordComponent
} from '../highlights/highlights-forgot-password/highlights-forgot-password.component';

@Component({
  selector: 'zbp-forgot-password-handler',
  template: '<ng-container *ngComponentOutlet="componentToUse | async"></ng-container>',
})
export class ForgotPasswordHandlerComponent extends ViewHandlerComponent {
  componentMap = {
    [CompanyCode.HighlightsPortal]: {
      component: HighlightsForgotPasswordComponent,
    },
    [CompanyCode.ZBPortal]: {
      component: ForgotPasswordComponent,
    }
  };
}
