import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RedirectIfActive } from '@core/redirect-if-active.service';

import {
  Login2024RedesignHandlerComponent
} from './branded-login/login-2024-redesign-handler/login-redesign-2024-handler.component';
import {
  ChooseRoleRedesignHandlerComponent
} from './choose-role/choose-role-redesign-handler/choose-role-redesign-handler.component';
import { ForgotPasswordHandlerComponent } from './forgot-password-handler/forgot-password-handler.component';
import { InvalidTokenComponent } from './invalid-token/invalid-token.component';
import { LoginComponent } from './login.component';
import { SkofLoginComponent } from './login.skof/login.skof.component';
import { StudentLoginComponent } from './login.student/login.student.component';
import { LtiAdvantageRedirectComponent } from './lti-advantage-redirect/lti-advantage-redirect.component';
import { LtiAdvantageRequestComponent } from './lti-advantage-request/lti-advantage-request.component';
import { LtiTokenExchangeComponent } from './lti-token-exchange/lti-token-exchange.component';
import { OAuth2Component } from './oauth2/oauth2.component';
import { SetupAccountHandlerComponent } from './setup-account-handler/setup-account-handler.component';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [RedirectIfActive],
    component: LoginComponent,
    children: [
      { path: '', component: ChooseRoleRedesignHandlerComponent },
      { path: 'teacher', redirectTo: 'user' },
      { path: 'parent', component: Login2024RedesignHandlerComponent, data: { formMode: 'parent' } }, // FormMode will toggle the form to be in parent mode
      { path: 'user', component: Login2024RedesignHandlerComponent, data: { formMode: 'user' } }, // FormMode will toggle the form to be in user mode
      { path: 'student', component: StudentLoginComponent },
      { path: 'forgot-password', component: ForgotPasswordHandlerComponent },
    ],
  },
  { path: 'login/skof', component: SkofLoginComponent, canActivate: [RedirectIfActive] },
  { path: 'set-password', component: SetupAccountHandlerComponent },
  { path: 'lti-token-exchange', canActivate: [RedirectIfActive], component: LtiTokenExchangeComponent },
  { path: 'account-setup', canActivate: [RedirectIfActive], component: SetupAccountHandlerComponent },
  { path: 'invalid-token', canActivate: [RedirectIfActive], component: InvalidTokenComponent },
  // The clever path is maintained for backwards-compatibility.
  { path: 'clever', component: OAuth2Component },
  { path: 'oauth2/:provider', component: OAuth2Component },
  { path: 'lti-advantage-request', component: LtiAdvantageRequestComponent },
  { path: 'lti-advantage-redirect', component: LtiAdvantageRedirectComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class LoginRoutingModule { }
