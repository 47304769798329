import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClassroomIcon } from '@models/classroom-icon';
import { ProductSku } from '@models/product';
import { IProductLine } from '@models/product-line';
import { TTLCache } from '@shared/ttl-cache';
import store, { StoreAPI } from 'store2';

/**
 * Service holds certain values to persist in local storage to be restored at a later time
 */
@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private store: StoreAPI;

  private apiCache: TTLCache<HttpResponse<any>> = new TTLCache();
  static readonly API_CACHE_STORAGE_KEY = 'api-ttl-cache';

  constructor() {
    this.store = store;
    this.apiCache = this.loadAPICacheFromLocalStorage();
    if (!this.apiCache) this.apiCache = new TTLCache();
  }

  clearAll(clearFeatureFlags: boolean = false, clearMasquerade: boolean = true): void {
    this.clearCoreApiCache();
    this.clearProductsData();
    this.clearLastSelectedSchoolId();
    this.clearClassroomIconCache();
    this.clearClassroomId();
    this.clearProductId();
    this.clearProductLineId();
    this.clearVariant();
    this.clearApiCache();

    if (clearFeatureFlags) {
      // do not clear feature flags while exiting from masquerading
      this.clearFeatureFlags();
    }

    if (clearMasquerade) {
      // do not clear masquerade cache during login process for masquerade since we have multi-levels of masquerade
      this.clearMasqueradedCache();
    }
  }

  get products(): IProductLine[] {
    const productLines = this.store.get('products');

    return productLines ? productLines.map((pl) => {
      const variants = pl.variants.map(variant => ({
        ...variant,
        skus: variant.skus.map(sku => new ProductSku(sku)),
      }));
      return { ...pl, variants };
    }) : [];
  }

  set products(value: IProductLine[]) {
    this.store.set('products', value);
  }

  clearProductsData() {
    this.store.remove('products');
  }

  /** Last School Id Used to Login via the student login */
  get lastSchoolIdUsed(): string {
    return this.store.get('lastSchoolIdUsed');
  }

  set lastSchoolIdUsed(val: string) {
    this.store.set('lastSchoolIdUsed', val);
  }

  /**
   * Last email used by a user via the teacher login
   * NOTE: This is purposely email, as student logins will not be 'remembered'
   */
  get lastEmailUsed(): string {
    return this.store.get('lastEmailUsed');
  }

  set lastEmailUsed(val: string) {
    this.store.set('lastEmailUsed', val);
  }

  /**
   * Last login path used by the actively logged in user to facilitate the proper redirection upon logout
   */
  get lastLoginPathUsed(): string {
    return this.store.get('lastLoginPathUsed');
  }

  setTeacherAsLastLoginPathUsed(): void {
    this.store.set('lastLoginPathUsed', 'user');
  }

  setParentAsLastLoginPathUsed(): void {
    this.store.set('lastLoginPathUsed', 'parent');
  }

  setStudentAsLastLoginPathUsed(): void {
    this.store.set('lastLoginPathUsed', 'student');
  }

  setSkofAsLastLoginPathUsed(): void {
    this.store.set('lastLoginPathUsed', 'skof');
  }

  get coreApiToken(): string {
    return this.store.get('coreApiToken');
  }

  set coreApiToken(value: string) {
    this.store.set('coreApiToken', value);
  }

  clearCoreApiCache() {
    this.store.remove('coreApiToken');
  }

  get isMasqueraded(): boolean {
    return !!this.store.get('isMasqueraded');
  }

  set isMasqueraded(value: boolean) {
    if (value) {
      this.store.set('isMasqueraded', '1');
    } else {
      this.store.remove('isMasqueraded');
    }
  }

  clearMasqueradedCache() {
    this.store.remove('isMasqueraded');
    this.store.remove('masqueradeOriginUrl');
    this.store.remove('userRoleCxSupportOrAboveBeforeMasquerade');
    this.store.remove('usersLastRole');
  }

  get masqueradeOriginUrl() {
    return this.store.get('masqueradeOriginUrl');
  }

  set masqueradeOriginUrl(value: string) {
    this.store.set('masqueradeOriginUrl', value);
  }

  get usersLastRole() {
    return this.store.get('usersLastRole');
  }

  // Needs set before masquerading at any level
  // This is to track the previous role before user masquerades as another user
  set usersLastRole(value: string) {
    this.store.set('usersLastRole', value);
  }

  get userRoleCxSupportOrAboveBeforeMasquerade() {
    return !!this.store.get('userRoleCxSupportOrAboveBeforeMasquerade');
  }

  set userRoleCxSupportOrAboveBeforeMasquerade(value: boolean) {
    this.store.set('userRoleCxSupportOrAboveBeforeMasquerade', value);
  }

  get lastSelectedSchoolId(): string {
    return this.store.get('lastSelectedSchoolId');
  }

  set lastSelectedSchoolId(value: string) {
    this.store.set('lastSelectedSchoolId', value);
  }

  clearLastSelectedSchoolId() {
    this.store.remove('lastSelectedSchoolId');
  }

  get classroomIcons(): ClassroomIcon[] {
    return this.store.get('classroomIcons');
  }

  set classroomIcons(value: ClassroomIcon[]) {
    this.store.set('classroomIcons', value);
  }

  clearClassroomIconCache() {
    this.store.remove('classroomIcons');
  }

  set classroomId(classroomId: string) {
    this.store.set('classroomId', classroomId);
  }

  get classroomId(): string {
    return this.store.get('classroomId');
  }

  clearClassroomId() {
    this.store.remove('classroomId');
  }

  set productId(productId: string) {
    this.store.set('productId', productId);
  }

  get productId(): string {
    return this.store.get('productId');
  }

  clearProductId() {
    this.store.remove('productId');
  }

  set productLineId(productLineId: string) {
    this.store.set('productLineId', productLineId);
  }

  get productLineId(): string {
    return this.store.get('productLineId');
  }

  clearProductLineId() {
    this.store.remove('productLineId');
  }

  set variant(variant: string) {
    this.store.set('variant', variant);
  }

  get variant(): string {
    return this.store.get('variant');
  }

  clearVariant() {
    this.store.remove('variant');
  }

  set redirectToDR(redirect: boolean) {
    this.store.set('redirectToDR', redirect);
  }

  get redirectToDR(): boolean {
    return this.store.get('redirectToDR');
  }

  clearRedirectToDR() {
    this.store.remove('redirectToDR');
  }

  set featureFlags(featureFlags: string) {
    this.store.set('featureFlags', featureFlags);
  }

  get featureFlags(): string {
    return this.store.get('featureFlags');
  }

  clearFeatureFlags(): void {
    this.store.remove('featureFlags');
  }

  getCachedApiCall(key: string): any | null {
    return this.apiCache.getValue(key);
  }

  cacheApiCall(key: string, value: HttpResponse<any>, ttl: number) {
    this.apiCache.setValue(key, value, ttl);
    this.saveAPICacheToLocalStorage();
  }

  clearApiCache(): void {
    this.apiCache.clear();
    this.store.remove(CacheService.API_CACHE_STORAGE_KEY);
  }

  clearCachedKey(key: string) {
    this.apiCache.clearCachedKey(key);
    this.saveAPICacheToLocalStorage();
  }

  clearCachedPattern(pattern: string) {
    this.apiCache.clearCachedPattern(pattern);
    this.saveAPICacheToLocalStorage();
  }

  saveAPICacheToLocalStorage() {
    try {
      this.store.set(CacheService.API_CACHE_STORAGE_KEY, this.apiCache.serializeCache());
    } catch (err) {
      console.error('Failed to save cache to local storage', err);
    }
  }

  loadAPICacheFromLocalStorage(): TTLCache<any> {
    try {
      const serializedCache = this.store.get(CacheService.API_CACHE_STORAGE_KEY);
      if (serializedCache) {
        return new TTLCache(serializedCache);
      }
    } catch (err) {
      console.error('Failed to load cache from local storage', err);
    }
    return null;
  }
}
