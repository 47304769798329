import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/auth.service';
import { BrowserService } from '@core/browser.service';

// Branded layout wrapper for the login form
@Component({
  selector: 'zbp-branded-login',
  templateUrl: './branded-login.component.html',
  styleUrls: ['./branded-login.component.scss'],
})
export class BrandedLoginComponent implements OnInit {
  isHighlights: boolean = false;
  brandClass: string = null;
  logoSrc: string = null;
  logoAlt: string = null;
  isLoading: boolean = false;

  constructor(
    private authService: AuthService,
    public browserService: BrowserService,
  ) { }

  ngOnInit(): void {
    this.isHighlights = this.authService.isHighlightsPortalUrl;
    if (this.isHighlights) {
      this.setupHighlightsPortalData();
    } else {
      this.setupZbPortalData();
    }

    this.isLoading = false;
  }

  /**
   * Used to setup data for HL Portal view. All items specific to HL Portal should be done here.
   */
  private setupHighlightsPortalData() {
    this.brandClass = 'hl-portal-login';
    this.logoSrc = 'assets/img/highlights-logo.svg';
    this.logoAlt = 'Highlights Portal logo';
  }

  /**
   * Used to setup data for ZB Portal view. All items specific to ZB Portal should be done here.
   */
  private setupZbPortalData() {
    this.brandClass = 'zb-portal-login';
    this.logoSrc = 'assets/zbportal/img/zb-logo.svg';
    this.logoAlt = 'ZB Portal logo';
  }
}
